<template>
  <div>
    <h3>First Law</h3>
    <p>
      Newton's first law states that:
      <br><i>"An object does not change its state of motion unless
        acted upon by an external force."
      </i>
      <br>This means that an object which is at rest will continue to be at rest
      and a moving object will keep moving if no external force is applied on it.
      Mathematically, we can say that:
      $$\textbf{F}_{net}=0 \iff \frac{d\textbf{v}}{dt}=0$$
      The concept of inertia is very closely related to the first law. In fact
      sometimes the first law is called the <i>Principle of Inertia</i>
    </p>
    <p>
      The Mathematical statements might not seem very intuitive. So let us look at some real
      life examples of the First Law and Inertia.
    </p>
    <ol>
      <li>
        When you get off a moving bus, you should run along the
        bus for sometime otherwise you will fall. This is because
        when your feet touch the ground, they stop moving but the
        upper body keeps moving due to the First Law. Hence one must
        run alongside the bus for a while before stopping slowly
      </li>
      <li>
        You must've also observed that people lean forward when a bus
        stops. This is also due to inertia of the upper body.
      </li>
      <li>
        If you were to visit the International Space Station, you would notice that stopping in space is
        much more difficult. This is because in outer space, there is no gravity to act against our motion.
      </li>
    </ol>
    <h3>Second Law</h3>
    <p>
      Newton's Second Law quantitatively defines Force but before we get there, lets first look
      at the qualitative definition of Force which we get from The First Law:
      <br><i>"Force is that physical cause which changes the state of rest or motion of a body" </i>
      <br> This definition comes from the First Law. Now we will see the Second Law:
      <br><i>"The rate of change of momentum of a body is directly proportional to the applied force and
        occurs in the same direction as the direction of the force applied." </i>
      Mathematically,
      $$\textbf{F}=\frac{d\textbf{p}}{dt}$$
      Using \(\textbf{p}=m\textbf{v}\) in the above equation,
      $$\textbf{F}=m\frac{d\textbf{v}}{dt}+\textbf{v}\frac{dm}{dt}$$
      In most cases, mass of our system remains constant, so the above
      equation reduces to the famous \(\textbf{F}=m\frac{d\textbf{v}}{dt}=m\textbf{a}\)
    </p>
    <p>
      There are however some cases where the mass of a system does not remain constant (like in a rocket
      which continuously uses up its fuel) and in such cases we cannot use \(\textbf{F}=m\textbf{a}\)
    </p>
    <h3>Third Law</h3>
    <p>
      Newton's third law is pretty simple. It states that:
      <br><i>"Every action has an equal and opposite reaction"</i>
      <br>Now lets see some examples to understand this:
    </p>
    <ol>
      <li>
        A rocket moves up because the gases which come out of it exert a force equal to the force
        applied by the rocket on the gas.
      </li>
      <li>When a gun is fired the shooter feels a recoil. This is due to the Third Law.</li>
    </ol>

    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve.</h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Newton\'s Laws of Motion';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Free Body Diagram',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
